import { useState, useEffect } from 'react';

let listeners = [];
let state = { 
	modalOpen: false, 
	zipcode: null,
	greyfinchID: "981581",
    showCallCenterModalOnClose: false,
    callCenterModalOpen: false
};

const setState = (newState) => {
  	state = { ...state, ...newState };
	listeners.forEach((listener) => {
		listener(state);
	});
};

function useModalState(){
  	const newListener = useState()[1];
	useEffect(() => {
		listeners.push(newListener);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => {
		// Called just before the component unmount
			listeners = listeners.filter(listener => listener !== newListener);
		};
	}, []);
  	return [state, setState];
};

export default useModalState;