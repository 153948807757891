import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styleSheet from "./index.module.scss"


const FluidImage = ({ image, passedClass = styleSheet.defaultStyles, loading = 'lazy', placeholder = 'BLURRED' }) => {

    if( !image ){
        return null;
    }
    
	const hasLocalFile = image.localFile !== undefined;
	const altText = image.altText ? image.altText : "";

	if( hasLocalFile && image.localFile.extension && image.localFile.extension === "svg"){
		return <div className={`${styleSheet.svgcontainer} ${passedClass}`}><img className={styleSheet.svg} src={image.localFile.publicURL} width="100" height="100" loading="lazy" alt={altText} /></div>;
	} else {
		const childImageSharp = hasLocalFile ? image.localFile.childImageSharp : image.childImageSharp;
  		return childImageSharp? <GatsbyImage
            image={childImageSharp.gatsbyImageData}
			placeholder={placeholder}
            className={passedClass}
            alt={altText}
            loading={loading} /> : null;
	}
}

export default FluidImage