import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useRef } from "react";
import useSmileAssessmentState from "../../helpers/smileAssessmentState"
import FixedImage from "../fixedImage";

import * as styleSheet from "./index.module.scss"

const SmileAssessmentModal = () => {

	const data = useStaticQuery(graphql`
		query {
			closeIcon: file(name: {eq: "close_icon"}) {
				childImageSharp {
				  gatsbyImageData(width: 32, layout: FIXED)
				}
			}
		}
	`);

	const [showAppointmentModal, setShowAppointmentModal] = useSmileAssessmentState(); 
	const iframeRef = useRef();	

	function hideModal(){
		if( showAppointmentModal.smileModalOpen ){
			setShowAppointmentModal({smileModalOpen: false});
		}
	}

	useEffect( () => {
	
		let successRedirectUrl = null;
		  
		window.addEventListener("message", function (e) {
			if (e.data.type === "resize") {
				if( iframeRef && iframeRef.current ){
					if (iframeRef.current.style.height !== e.data.height) {
						iframeRef.current.style.height = e.data.height;
				 	}

				  	if (e.data.scroll) {
						iframeRef.current.scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
						});
				  	}
				}
				if (
				  	successRedirectUrl &&
				  	e.data.type === "vc-event" &&
					e.data.event === "SCREENING_SUBMITTED"
				) {
					setTimeout(function () {
						document.location.assign(successRedirectUrl);
					});
				}
			}
		});

		return () => window.removeEventListener("message", function(){});
	
	}, []);

	let iframeSrc = `https://us.smilemate.com/practice/${showAppointmentModal.iframeSrcToken}/scan`;

	return (
		<div className={styleSheet.appointmentmodal}>
			<div className={styleSheet.appointmentmodal__inner}>
				{<button onClick={hideModal} className={styleSheet.close} aria-label="Hide smile assessment modal"><span className={styleSheet.sronly}>Close modal</span> <FixedImage image={data.closeIcon} /></button>}
				<iframe ref={iframeRef} className={styleSheet.greyfinchIframe} src={iframeSrc} width="100%" title="SmileMate App"></iframe>
			</div>
		</div>
	);
};

export default SmileAssessmentModal;