import React, { useEffect, useState, useRef } from 'react';
import { Transition } from 'react-transition-group';

import * as styleSheet from "./index.module.scss"


const Submenu = ({showIf, changeState, passedClass, children, orientation = "normal"}) => {

	const [ windowWidth, setWindowWidth ] = useState(0);
	const [ left, setLeft ] = useState(0);
	const [pointerOffset, setPointerOffset] = useState(0);
	const [opac, setOpac] = useState(0);
	const containerEl = useRef(null);

	const containerTransitionStyles = {
		entering: { 
			left: `${left}px`,
			opacity: 0,
			margin: (orientation === "flipped") ? "0" : "24px 0 0 0",
		},
		entered: { 
			left: `${left}px`,
			opacity: opac,
			margin: (orientation === "flipped") ? "0 0 10px 0" : "4px 0 0 0",
		},
		exiting: { 
			left: `${left}px`,
			opacity: 0,
			margin: (orientation === "flipped") ? "0" : "24px 0 0 0",
		},
		exited: { 
			left: `${left}px`,
			opacity: 0,
			margin: (orientation === "flipped") ? "0" : "24px 0 0 0",
		},
 	};

	const pointerStyles = {
		left: `${pointerOffset}px`,
 	};

	useEffect( () => {
		const onWindowResize = () => {
			if(typeof window !== undefined){ 
				setWindowWidth(window.innerWidth);

				if( changeState !== undefined ){
					changeState(false);
				}

				if (containerEl.current) {
					centerDiv();
				}
			}
		};

		if(typeof window !== undefined){
			setWindowWidth(window.innerWidth)
			window.addEventListener('resize', onWindowResize);
		}

		return () => {
			if(typeof window !== undefined){
				window.removeEventListener('resize', onWindowResize);
			}
		}
	}, [changeState]);

	const onOpen = (node, isAppearing) => {
		if (containerEl.current) {
			centerDiv();
		}
	};

	const onExited = (node) => {
		setLeft(0);
		setPointerOffset(0);
	};

	const centerDiv = () => {
		setOpac(0);
		setTimeout(() => {
			const parentLiHalf = containerEl.current.parentElement.getBoundingClientRect().width / 2;
			let offset = containerEl.current.getBoundingClientRect();
			let diff = -Math.abs(offset.width / 2);

			setLeft(diff);
			setPointerOffset(diff + parentLiHalf);

			offset = containerEl.current.getBoundingClientRect();

			if (offset.right >= window.innerWidth) {
				diff = -Math.abs((offset.width / 2));
			} else if (offset.left <= 0) {
				diff = -Math.abs((offset.width / 2) + (offset.left - 15));
			}

			setLeft(diff);
			setPointerOffset(Math.abs(diff) + parentLiHalf);
			setOpac(1);
		}, 500);
	};
	
	return(
		<Transition in={showIf} timeout={250} mountOnEnter unmountOnExit onEnter={(node,isAppearing)=>{ onOpen(node, isAppearing) }} onExited={(node)=>{ onExited(node) }}>
			{(state) => (
				<div className={passedClass} style={{...containerTransitionStyles[state]}} ref={containerEl}>
					<div className={orientation === "flipped" ? styleSheet.pointerflipped : styleSheet.pointer } style={{...pointerStyles}}></div>
					{children}
				</div>
			)}
		</Transition>
	);
}

export default Submenu;
