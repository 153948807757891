import React, { useEffect, useContext, useState } from "react";
import { Link } from 'gatsby';
import formatPhone from "../../helpers/formatPhone";
import FixedImage from "../fixedImage";
import ThemeContext from '../../context/ThemeContext';
import useModalState from '../../helpers/modalState';

import * as styleSheet from "./index.module.scss"

const NearestLocation = ({locations, arrowGrayLocal, arrowRedLocal}) => {

	const [showAppointmentModal, setShowAppointmentModal] = useModalState();
  
	function showModal(){
		  if( !showAppointmentModal.modalOpen ){
			  setShowAppointmentModal({modalOpen: true});
		  }
	  }
  
	function handleApptKeyDown(e) {
	  if (e.keyCode === 13 || e.keyCode === 32) {
			  setShowAppointmentModal({modalOpen: true});
		  }
	}



	const { location, getLocation } = useContext(ThemeContext);
	const [ nearestLoc, setNearestLoc ] = useState(null);

	useEffect( () => {
		function sortByDistance(){

			let locationList = [];
	
			locations.forEach((wpLocation, i) => {   
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});
	
			// apply sorted list to state
			setNearestLoc(locationList[0]);
	
			function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
		}

		getLocation();
		sortByDistance();

	}, [location, locations, getLocation])

  	return (
		<div className={styleSheet.NearestLocation}>
			{ nearestLoc === null && (
				<div className={styleSheet.top}>
					<span className={styleSheet.title}>Loading...</span>
				</div>
			)}
			{
				nearestLoc !== null && (
					<>
						<div className={styleSheet.top}>
							<Link to={nearestLoc.link} className={styleSheet.title} dangerouslySetInnerHTML={{__html: nearestLoc.title}}></Link>
							{ nearestLoc.ACFLocation.briefDescription && <p dangerouslySetInnerHTML={{__html: nearestLoc.ACFLocation.briefDescription.length > 100 ? nearestLoc.ACFLocation.briefDescription.substring(0, 99) + "..." : nearestLoc.ACFLocation.briefDescription}}></p>}
							<Link to={nearestLoc.link} className={styleSheet.learnmore}>Learn more <span className={styleSheet.sronly}>about {nearestLoc.title}</span><FixedImage image={arrowGrayLocal} passedClass={styleSheet.icon} /></Link>
						</div>
						<div className={styleSheet.bottom}>
							{ nearestLoc.birdeyeLocation.location.address1 && <span className={styleSheet.address} dangerouslySetInnerHTML={{__html: nearestLoc.birdeyeLocation.location.address1 }}></span>}
							{ nearestLoc.birdeyeLocation.location.address2 && <span className={styleSheet.address} dangerouslySetInnerHTML={{__html: nearestLoc.birdeyeLocation.location.address2 }}></span>}
							{ nearestLoc.birdeyeLocation.location.zip && nearestLoc.birdeyeLocation.location.state && nearestLoc.birdeyeLocation.location.city && <span className={styleSheet.address} dangerouslySetInnerHTML={{__html: `${nearestLoc.birdeyeLocation.location.city}, ${nearestLoc.birdeyeLocation.location.state} ${nearestLoc.birdeyeLocation.location.zip}`}}></span>}
							{ nearestLoc.birdeyeLocation.phone && <a className={styleSheet.phone} href={`tel:${nearestLoc.birdeyeLocation.phone}`}>{formatPhone(nearestLoc.birdeyeLocation.phone)}</a>}

							{ nearestLoc.ACFLocation.bookMyAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{nearestLoc.ACFLocation.bookMyAppointmentLinkText} <FixedImage image={arrowRedLocal} passedClass={styleSheet.icon} /></button> }
							{ !nearestLoc.ACFLocation.bookMyAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>Book Your First Visit <FixedImage image={arrowGrayLocal} passedClass={styleSheet.icon} /></button> }
						</div>
					</>
				)
			}
		</div>
  	)
}

export default NearestLocation;
