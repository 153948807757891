import React, { useState } from "react";
import { Transition } from 'react-transition-group';
import WPLink from "../wpLink";
import FixedImage from "../fixedImage";
import { Link } from "gatsby";
import useModalState from '../../helpers/modalState';

import * as styleSheet from "./index.module.scss"


const MobileNav = ({mainNavigationSettings, menuIcon, closeIcon, services, arrowBlue, locations, dentists, hideAppointmentButton, zip}) => {
  
	const {
		showLocationsInMenu,
		showServicesInMenu,
		showTeamInMenu,
		teamMenuTitle,
		servicesMenuTitle,
		servicesMegamenu: {
			servicesList
		},
		navCta,
		links,
		mobileNavLinks,
		locationsMenuTitle,
		headerLogo
	} = mainNavigationSettings;

	const [ navOpen, setNavOpen ] = useState(false);
	const [ servicesOpen, setServicesOpen ] = useState(false);
	const [ locationsOpen, setLocationsOpen ] = useState(false);
	const [ teamOpen, setTeamOpen ] = useState(false);

	const [showAppointmentModal, setShowAppointmentModal] = useModalState();

	function showModal(){
		  if( !showAppointmentModal.modalOpen ){
			  setShowAppointmentModal({modalOpen: true, zipcode: zip});
		  }
	  }
  
	function handleApptKeyDown(e) {
	  if (e.keyCode === 13 || e.keyCode === 32) {
			  setShowAppointmentModal({modalOpen: true, zipcode: zip});
		  }
	}



	const toggleNav = () => {
		const toggled = !navOpen;
		setNavOpen(toggled);
	};

	const closeNav = () => {
		setNavOpen(false);
	};

	const toggleServices = () => {
		const toggled = !servicesOpen;
		setServicesOpen(toggled);
	};

	const toggleLocations = () => {
		const toggled = !locationsOpen;
		setLocationsOpen(toggled);
	};

	const toggleTeam = () => {
		const toggled = !teamOpen;
		setTeamOpen(toggled);
	};

	const containerTransitionStyles = {
		entering: { 
			opacity: 0,
		},
		entered:  { 
			opacity: 1,
		},
		exiting:  { 
			opacity: 0,
		},
		exited:  { 
			opacity: 0,
		},
 	};

	 const shroudTransitionStyles = {
		entering: { 
			opacity: 0,
		},
		entered:  { 
			opacity: 0.3,
		},
		exiting:  { 
			opacity: 0,
		},
		exited:  { 
			opacity: 0,
		},
 	};

	 const accordionTransitionStyles = {
		entering: { 
			opacity: "0",
			margin: "0 0 0px 0",
			maxHeight: "0",
		},
		entered:  { 
			opacity: "1",
			margin: "0 0 20px 0",
			maxHeight: "100px",
		},
		exiting:  { 
			opacity: "0",
			margin: "0 0 0px 0",
			maxHeight: "0",
		},
		exited:  { 
			opacity: "0",
			margin: "0 0 0px 0",
			maxHeight: "0",
		},
 	};

  	return (
		<nav className={styleSheet.mobilenav} role="navigation">
			<Transition in={navOpen} timeout={250} mountOnEnter unmountOnExit>
				{(state) => (
					<>
						<div role="button" aria-label="close mobile menu" className={styleSheet.shroud} style={{...shroudTransitionStyles[state]}} onClick={() => { closeNav(); }} onKeyDown={(event) => { if (event.keycode === 13){ closeNav(); }}} tabIndex={0}></div>
						<div className={styleSheet.top} style={{...containerTransitionStyles[state]}}>
							<div className={styleSheet.logosection}>
								<Link to="/" className={styleSheet.logolink}>
									{ headerLogo && <FixedImage image={headerLogo} className={styleSheet.logo} /> }
								</Link>
							</div>
							<div className={styleSheet.middle}>
								{
									showServicesInMenu && (
										<>
											<button className={servicesOpen ? styleSheet.navlisttoggleactive : styleSheet.navlisttoggle} onClick={()=>{ toggleServices(); }}>{servicesMenuTitle ? servicesMenuTitle : "Services" }</button>
											<Transition in={servicesOpen} timeout={250} mountOnEnter unmountOnExit>
												{(state) => (
													<ul className={styleSheet.navlist}>
														{servicesList ? servicesList.map( (service, index) => {
															return (
																<li key={`servicelink-${service.id}`} className={styleSheet.navlistitem} style={{...accordionTransitionStyles[state]}}>
																	<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
																	<Link to={service.link} className={styleSheet.link}  dangerouslySetInnerHTML={{__html: service.title}}></Link>
																</li>
															)
														}) : services && services.map( (service, index) => {
															return (
																<li key={`servicelink-${service.id}`} className={styleSheet.navlistitem} style={{...accordionTransitionStyles[state]}}>
																	<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
																	<Link to={service.link} className={styleSheet.link}  dangerouslySetInnerHTML={{__html: service.title}}></Link>
																</li>
															)
														})}
													</ul>
												)}
											</Transition>
										</>
									)
								}
								{
									showLocationsInMenu && (
										<>
											<button className={locationsOpen ? styleSheet.navlisttoggleactive : styleSheet.navlisttoggle} onClick={()=>{ toggleLocations(); }}>{locationsMenuTitle ? locationsMenuTitle : "Locations" }</button>
											<Transition in={locationsOpen} timeout={250} mountOnEnter unmountOnExit>
												{(state) => (
													<ul className={styleSheet.navlist}>
														{locations && locations.map( (location, index) => {
															return (
																<li key={`locationlink-${location.id}`} className={styleSheet.navlistitem} style={{...accordionTransitionStyles[state]}}>
																	<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
																	<Link to={location.link} className={styleSheet.link}  dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}}></Link>
																</li>
															)
														})}
													</ul>
												)}
											</Transition>
										</>
									)
								}
								{
									showTeamInMenu && (
										<>
											<button className={teamOpen ? styleSheet.navlisttoggleactive : styleSheet.navlisttoggle} onClick={()=>{ toggleTeam(); }}>{teamMenuTitle ? teamMenuTitle : "Our Team" }</button>
											<Transition in={teamOpen} timeout={250} mountOnEnter unmountOnExit>
												{(state) => (
													<ul className={styleSheet.navlist}>
														{dentists && dentists.map( (dentist, index) => {
															return (
																<li key={`dentistlink-${dentist.id}`} className={styleSheet.navlistitem} style={{...accordionTransitionStyles[state]}}>
																	<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
																	<Link to={dentist.link} className={styleSheet.link}  dangerouslySetInnerHTML={{__html: dentist.title}}></Link>
																</li>
															)
														})}
													</ul>
												)}
											</Transition>
										</>
									)
								}
								{
									links && links.map( (link, index) => {
										return <WPLink key={`extralink-${index}`} link={link.link} passedClass={styleSheet.linkstyles} />;
									})
								}
							</div>
							{
								mobileNavLinks && (
									<div className={styleSheet.secondarylinks}>
										<ul className={styleSheet.navlist}>
											{mobileNavLinks.map( (link, index) => {
												return (
													<li key={`secondlink-${index}`} className={styleSheet.navlistitem}>
														<WPLink link={link.mobileLinkItem} passedClass={styleSheet.link} />
													</li>
												)
											})}
										</ul>				
									</div>
								)
							}
						</div>
					</>
				)}
			</Transition>
			<div className={hideAppointmentButton ? styleSheet.bottomnocta : styleSheet.bottom}>
				{ !hideAppointmentButton && (
          navCta?.url.includes('schedule-online') ? <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.cta} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{navCta.title}</button> : <WPLink link={navCta} passedClass={styleSheet.cta} />
        )}
				<button className={styleSheet.togglemenu} onClick={()=>{toggleNav();}}>{ navOpen ? "Close" : "Menu"}{navOpen ? <FixedImage image={closeIcon} passedClass={styleSheet.close} /> : <FixedImage image={menuIcon} passedClass={styleSheet.menu} /> }</button>
			</div>
		</nav>
	);
}

export default MobileNav;
