import React, { useEffect, useContext, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import FluidImage from "../fluidImage";
import FixedImage from "../fixedImage";
import WPLink from "../wpLink";
import useModalState from "../../helpers/modalState";
import ThemeContext from '../../context/ThemeContext';
import loadable from '@loadable/component';

import * as styleSheet from "./index.module.scss"

const Footer = ({hideSmilesSection, allSmiles = null, zip}) => {
	const SmileGallerySection = loadable(() => import('../smileGallerySection'));
  const [showAppointmentModal, setShowAppointmentModal] = useModalState();
  const [ nearestTenLocations, setNearestTenLocations ] = useState([]);

  function showModal(){
		if( !showAppointmentModal.modalOpen ){
			setShowAppointmentModal({modalOpen: true, zipcode: zip});
		}
	}

	const data = useStaticQuery(graphql`query FooterQuery {
  options: wp {
    socialSection {
      ACFSocial {
        facebookUrl
        instagramUrl
        twitterUrl
      }
    }
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          servicesMegamenu {
            servicesList {
              ... on WpService {
                link
                title
                id
                ACFService {
                  briefDescription
                  serviceIcon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      publicURL
                      extension
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    footerSection {
      ACFFooterOptions {
        footerBackgroundImageMobile: footerBackgroundImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 699, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
        footerBackgroundImageTablet: footerBackgroundImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 999, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
        footerBackgroundImageDesktop: footerBackgroundImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            publicURL
            extension
          }
        }
        footerBackgroundImageXL: footerBackgroundImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            publicURL
            extension
          }
        }
        footerCalloutBlurb
        footerCalloutHeading
        footerAppointmentLinkText
        footerCalloutLogo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 289, layout: CONSTRAINED, quality: 100)
            }
            publicURL
            extension
          }
        }
        footerMenu {
          link {
            target
            title
            url
          }
        }
		copyrightText
        footerServicesTitle
        footerLocationsPageLink {
          target
          title
          url
        }
      }
    }
  }
  locations: allWpLocation(sort: {fields: title}) {
    nodes {
      id
      title
      link
      ACFLocation {
        menuTitle
      }
			birdeyeLocation {
				location {
					lat
					lng
					city
					state
				}
			}
    }
  }
  services: allWpService(sort: {fields: title}) {
    nodes {
      title
      link
      id
    }
  }
  rightArrowGreen: file(name: {eq: "arrow_green"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  facebook: file(name: {eq: "facebook"}) {
    childImageSharp {
      gatsbyImageData(width: 20, layout: FIXED)
    }
  }
  instagram: file(name: {eq: "instagram"}) {
    childImageSharp {
      gatsbyImageData(width: 20, layout: FIXED)
    }
  }
  twitter: file(name: {eq: "twitter"}) {
    childImageSharp {
      gatsbyImageData(width: 24, layout: FIXED)
    }
  }
}
`);

	const {
		options: {
      headerSection: {
        ACFHeaderOptions: {
          mainNavigationSettings: {
            servicesMegamenu: {
              servicesList
            }
          }
        }
      },
			footerSection: {
				ACFFooterOptions: {
					footerBackgroundImageMobile,
					footerBackgroundImageTablet,
					footerBackgroundImageDesktop,
					footerBackgroundImageXL,
					footerCalloutBlurb,
					footerCalloutHeading,
					footerAppointmentLinkText,
					footerCalloutLogo,
          			footerServicesTitle,
					footerMenu,
					copyrightText,
					footerLocationsPageLink
				}
			},
			socialSection: {
				ACFSocial: {
					facebookUrl,
					instagramUrl,
					twitterUrl,
				}
			}
		},
		locations: {
			nodes: locations
		},
		services: {
			nodes: services
		},
    rightArrowGreen,
		facebook,
		instagram,
		twitter
	} = data;


  const { location, getLocation } = useContext(ThemeContext);

	useEffect( () => {
		function sortByDistance(){

			let locationList = [];
	
			locations.forEach((wpLocation, i) => {   
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});

      const tenClosestLocations = locationList.slice(0, 10);
      setNearestTenLocations(tenClosestLocations);
	
			function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
		}

		getLocation();
		sortByDistance();

	}, [location, locations, getLocation])

	return (
		<footer className={styleSheet.Footer}>
      {!hideSmilesSection && <SmileGallerySection allSmiles={allSmiles} />}
			<div className={styleSheet.inner}>
				{ footerBackgroundImageMobile && <FluidImage passedClass={styleSheet.bgmobile} image={footerBackgroundImageMobile} /> }
				{ footerBackgroundImageTablet && <FluidImage passedClass={styleSheet.bgtablet} image={footerBackgroundImageTablet} /> }
				{ footerBackgroundImageDesktop && <FluidImage passedClass={styleSheet.bgdesktop} image={footerBackgroundImageDesktop} /> }
				{ footerBackgroundImageXL && <FluidImage passedClass={styleSheet.bgxl} image={footerBackgroundImageXL} /> }
				
				<div className={styleSheet.callout} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
					<div className={styleSheet.top}>
						{ footerCalloutLogo && <Link to={'/'}><FluidImage image={footerCalloutLogo} passedClass={styleSheet.logo} /></Link> }
						{ (facebookUrl || instagramUrl || twitterUrl) && (
							<div className={styleSheet.social}>
								{ facebookUrl && <a href={facebookUrl} target="_blank" rel="noopener noreferrer"><span>Facebook</span><FixedImage image={facebook} passedClass={styleSheet.fb} /></a> }
								{ twitterUrl && <a href={twitterUrl} target="_blank" rel="noopener noreferrer"><span>Twitter</span><FixedImage image={twitter} passedClass={styleSheet.twitter} /></a> }
								{ instagramUrl && <a href={instagramUrl} target="_blank" rel="noopener noreferrer"><span>Instagram</span><FixedImage image={instagram} passedClass={styleSheet.twitter} /></a> }
							</div>
						) }
					</div>
					<div className={styleSheet.bottom}>
						{ footerCalloutHeading && <span className={styleSheet.heading} dangerouslySetInnerHTML={{__html: footerCalloutHeading}}></span> }
						{ footerCalloutBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: footerCalloutBlurb}}></div> }

            { footerAppointmentLinkText && <button onClick={showModal} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{footerAppointmentLinkText} <FixedImage image={rightArrowGreen} passedClass="icon" /></button>}

					</div>
				</div>
				<div className={styleSheet.menus}>
					{ services.length > 0 && ( 
				  	<div className={styleSheet.services}>
                <ul className={styleSheet.list}>
                  { footerServicesTitle ? <li className={`${styleSheet.listitemmain} ${styleSheet.desktoponly}`}><span className={styleSheet.link}>{footerServicesTitle}</span></li> : <li className={styleSheet.listitemmain}><span className={styleSheet.link}>Treatments</span></li>}
                  { servicesList ? servicesList.map( (service, _index) => {
                    return (
                      <li key={service.id} className={styleSheet.listitemsecondary}>
                        <Link to={service.link} className={styleSheet.link}>
                          {service.title}
                        </Link>
                      </li>
                    );
                  }) : servicesList.map( (service, _index) => {
                    return (
                      <li key={service.id} className={styleSheet.listitemsecondary}>
                        <Link to={service.link} className={styleSheet.link}>
                          {service.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
					  </div>
					)}
					{ nearestTenLocations.length > 0 && ( 
				  	<div className={styleSheet.locations}>
                <ul className={styleSheet.list}>
                  { footerLocationsPageLink && <li className={styleSheet.listitemmain}><WPLink link={footerLocationsPageLink} passedClass={styleSheet.link} icon={rightArrowGreen} passedIconClass={styleSheet.listitemmainicon} /></li> }
                  { nearestTenLocations.map( (location, _index) => {
                    return (
                      <li key={location.id} className={styleSheet.listitemsecondary}>
                        <Link to={location.link} className={styleSheet.link}>
                          {location.birdeyeLocation.location.city} {location.birdeyeLocation.location.state}
                          {location?.ACFLocation?.menuTitle && <span className={styleSheet.alternativetitle}>{location.ACFLocation.menuTitle}</span>}
                        </Link>
                      </li>
                    );
                  })}
                {footerLocationsPageLink && <li className={styleSheet.seelocationslink}><WPLink link={{target: footerLocationsPageLink.target, title: 'See all locations', url: footerLocationsPageLink.url}} passedClass={styleSheet.link} passedIconClass={styleSheet.icon} icon={rightArrowGreen} /></li>}
                </ul>
					  </div>
					)}
   
          { footerMenu && (
            <div className={styleSheet.footermenu}>
              <nav className={styleSheet.nav}>
                <ul className={styleSheet.list}>
                  { footerMenu.map( (menuitem, index) => {
                    return (
                      <li key={index} className={styleSheet.listitemmain}>
                        <WPLink link={menuitem.link} passedClass={styleSheet.link} icon={rightArrowGreen} passedIconClass={styleSheet.listitemmainicon}/>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          )}
				</div>
			
				<div className="be-ix-link-block"></div>

				<div className={styleSheet.copyright}>{new Date().getFullYear()} {copyrightText ? copyrightText : " © All Rights Reserved" }</div>
			</div>
		</footer>
	);
};

export default Footer;
